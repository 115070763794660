import { commandHistoryApi } from "../api/hes/command-historySlice";
import { dropdownsApi } from "../api/hes/drop-downSlice";
import { pushDataApi } from "../api/hes/push-dataSlice";
import { meterConfigurationApi } from "../api/hes/meter-configurationSlice";
import { energyConsumptionApi } from "../api/mdms/energy-consumptionSlice";
import { operationalStatsApi } from "../api/mdms/operational-statisticsSlice";
import { loadsApi } from "../api/mdms/loadSlice";
import { userConsumptionApi } from "../api/mdms/userConsumptionSlice";
import { energyConsumptionApiV2 } from "../api/mdms/energy-consumptionSliceV2";
import { slaReportsApi } from "@/api/sla-reports";
import { gisApi } from "@/api/gis/gis-slice";
import { HealthPortalApi } from "@/api/coliving/health-portalSlice";
import { selectSiteApi } from "@/api/coliving/selectSiteNameSlice";
import { tariffDataApi } from "@/api/prepaid-config/tariffSlice";
import { factoryPush } from "@/api/factory-push";
import { holidaysApi } from "@/api/prepaid-config/holidaysSlice";
import { featuresAccessPanelApi } from "@/api/prepaid-config/features-access-panelSlice";
import { arrearConfigApi } from "@/api/prepaid-config/arrearConfigSlice";
import { prepaidDashboardSlice } from "@/api/prepaid-dashboard";
import { supersetApi } from "@/api/superset";
import { dtFeederMonitorSlice } from "@/api/dt-feeder-monitoring";
import { billingDashboardSlice } from "@/api/billing-dashboard";
import { timeDriftAPI } from "@/api/hes/timeDrift";
import { cognitoUserAccessPanelApi } from "@/api/cognito-user-access-panel";
import { mdmsCommandExecutionApi } from "@/api/mdms/command-executionSlice";
import { authApi } from "@/api/cognito-user-access-panel/auth";
import { NFMSAPI } from "@/api/nfms";

const middleware = [
  slaReportsApi.middleware,
  commandHistoryApi.middleware,
  dropdownsApi.middleware,
  pushDataApi.middleware,
  meterConfigurationApi.middleware,
  energyConsumptionApi.middleware,
  operationalStatsApi.middleware,
  loadsApi.middleware,
  userConsumptionApi.middleware,
  energyConsumptionApiV2.middleware,
  gisApi.middleware,
  HealthPortalApi.middleware,
  selectSiteApi.middleware,
  tariffDataApi.middleware,
  factoryPush.middleware,
  holidaysApi.middleware,
  featuresAccessPanelApi.middleware,
  cognitoUserAccessPanelApi.middleware,
  arrearConfigApi.middleware,
  prepaidDashboardSlice.middleware,
  timeDriftAPI.middleware,
  supersetApi.middleware,
  dtFeederMonitorSlice.middleware,
  billingDashboardSlice.middleware,
  mdmsCommandExecutionApi.middleware,
  authApi.middleware,
  NFMSAPI.middleware,
];

export default middleware;
