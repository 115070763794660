import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Col,
  Form,
  Button,
  Label,
  InputGroup
} from 'reactstrap';
import InputPasswordToggle from '../../../../@core/components/input-password-toggle';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useChangePasswordMutation } from '@/api/cognito-user-access-panel';

const MySwal = withReactContent(Swal);

const UpdatePassword = () => {
  const [updatePassword, updatePasswordResponse] = useChangePasswordMutation();

  const [formValues, setFormValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const handleConfirmText = async () => {
    const result = await MySwal.fire({
      text: 'Are you sure you want to update it?',
      title: 'Are you sure!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update it!',
      customClass: {
        confirmButton: 'btn btn-primary me-1',
        cancelButton: 'btn btn-outline-danger '
      },
      buttonsStyling: false
    });

    if (result.isConfirmed) {
      if (
        formValues.oldPassword === formValues.newPassword &&
        formValues.oldPassword === formValues.confirmPassword
      ) {
        toast('New password should not be same as old password', {
          hideProgressBar: true,
          type: 'error'
        });
        return;
      } else if (formValues.newPassword !== formValues.confirmPassword) {
        toast('New password and confirm password do not match.', {
          hideProgressBar: true,
          type: 'error'
        });
        return;
      }
      postUpdatePassword();
    }
  };

  const postUpdatePassword = () => {
    const payload = {
      ...formValues
    };

    updatePassword(payload);
  };

  useEffect(() => {
    if (updatePasswordResponse.status === 'fulfilled') {
      MySwal.fire({
        icon: 'success',
        title: 'Please notice!',
        text: updatePasswordResponse.data?.message,
        customClass: {
          confirmButton: 'btn btn-success'
        }
      }).then(() => {
        setFormValues({
          newPassword: '',
          oldPassword: '',
          confirmPassword: ''
        });
      });
    } else if (updatePasswordResponse.isError) {
      const error = updatePasswordResponse.error.data.message;
      toast(error, { hideProgressBar: true, type: 'error' });
    }
  }, [updatePasswordResponse]);

  const onChangePassword = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValues((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValues.password === formValues.password2) {
      handleConfirmText();
    } else {
      toast('Re-check Password.', {
        hideProgressBar: true,
        type: 'error'
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">New Password</CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup row>
            <Label sm="4" for="oldPassword">
              Old Password
            </Label>
            <Col sm="8">
              <InputGroup className="input-group-merge">
                <InputPasswordToggle
                  id="old_pass"
                  name="oldPassword"
                  className="input-group-merge"
                  value={formValues.old_pass}
                  onChange={onChangePassword}
                  required
                />
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm="4" for="newPassword">
              New Password
            </Label>
            <Col sm="8">
              <InputGroup className="input-group-merge">
                <InputPasswordToggle
                  id="password"
                  name="newPassword"
                  className="input-group-merge"
                  value={formValues.password}
                  onChange={onChangePassword}
                  required
                />
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm="4" for="confirmPassword">
              Confirm New Password
            </Label>
            <Col sm="8">
              <InputGroup className="input-group-merge">
                <InputPasswordToggle
                  id="password2"
                  name="confirmPassword"
                  className="input-group-merge"
                  value={formValues.password2}
                  onChange={onChangePassword}
                  required
                />
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup className="mb-0" row>
            <Col className="d-flex" md={{ size: 9, offset: 3 }}>
              <Button className="me-1" color="primary" type="submit">
                Submit
              </Button>
              <Button
                outline
                color="secondary"
                type="reset"
                onClick={() => {
                  setFormValues({
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                  });
                }}
              >
                Reset
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default UpdatePassword;
