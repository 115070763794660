import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../utils/baseQueryWithAuth";

const nfmsUrl = process.env.REACT_APP_NFMS_URL;

export const NFMSAPI = createApi({
  reducerPath: "NFMSAPI",
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getInstallFeederMasterData: builder.mutation({
      query: (data) => ({
        url: `${nfmsUrl}/install_feeder_master_data/consumers/`,
        body: data,
        method: "POST",
      }),
    }),
    getBLockLoadLogging: builder.mutation({
      query: (data) => ({
        url: `${nfmsUrl}/block_load_logging/consumers/`,
        body: data,
        method: "POST",
      }),
    }),
    getBlockLoadTransactionalData: builder.query({
      query: (params) => ({
        url: `${nfmsUrl}/transactional_data/block_load/${params.transaction_id}/${params.nfms_sync_status}`,
      }),
    }),
    getRealTimeAlarmsLoggin: builder.mutation({
      query: (data) => ({
        url: `${nfmsUrl}/real_time_alarms/consumers/`,
        body: data,
        method: "POST",
      }),
    }),
    getSuccessFullNFMSLogs: builder.mutation({
      query: (data) => ({
        url: `${nfmsUrl}/success_request_response_logs/`,
        body: data,
        method: "POST",
      }),
    }),
    getFailedNFMSLogs: builder.mutation({
      query: (data) => ({
        url: `${nfmsUrl}/failed_request_response_logs/`,
        body: data,
        method: "POST",
      }),
    }),
    intallFeederMasterDataFilters: builder.query({
      query: () => ({
        url: `${nfmsUrl}/dashboard_apis/install_feeder_master_data/filters`,
      }),
    }),
    blockLoadSyncFilters: builder.query({
      query: () => ({
        url: `${nfmsUrl}/dashboard_apis/block_load/filters`,
      }),
    }),
    realtimeAlarmsFilter: builder.query({
      query: () => ({
        url: `${nfmsUrl}/dashboard_apis/real_time_alarms/filters`,
      }),
    }),
    apiLogsFilters: builder.query({
      query: () => ({
        url: `${nfmsUrl}/dashboard_apis/api_logs/filters`,
      }),
    }),
  }),
});

export const {
  useGetInstallFeederMasterDataMutation,
  useGetBLockLoadLoggingMutation,
  useGetFailedNFMSLogsMutation,
  useGetRealTimeAlarmsLogginMutation,
  useGetSuccessFullNFMSLogsMutation,
  useApiLogsFiltersQuery,
  useBlockLoadSyncFiltersQuery,
  useIntallFeederMasterDataFiltersQuery,
  useRealtimeAlarmsFilterQuery,
  useGetBlockLoadTransactionalDataQuery,
} = NFMSAPI;
