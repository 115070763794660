import { useState, useEffect } from "react";
import { Input } from "reactstrap";

import { useDispatch } from "react-redux";
import {
  useLazyGetMdmsSearchResultQuery,
  useGetConsumersEnergyConsumptionQuery,
} from "../../../api/mdms/energy-consumptionSliceV2";

import { useLocation } from "react-router-dom";
import { updateMDMSHierarchyProgress } from "@/app/redux/mdmsHeirarchySlice";
import { toast } from "react-toastify";

const NavbarSearch = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const moduleName = location.pathname.split("/")[3];
  const [searchQuery, setSearchQuery] = useState("");
  const [execSearch, searchRes] = useLazyGetMdmsSearchResultQuery({
    search_query: searchQuery,
  });
  const [searchResult, setSearchResult] = useState([]);
  const [params, setParams] = useState();
  const { data, status, isError } = useGetConsumersEnergyConsumptionQuery(
    params,
    { skip: !params }
  );
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchQuery.length > 3) {
        execSearch({ search_query: searchQuery });
      } else {
        setSearchResult([]);
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchQuery]);

  useEffect(() => {
    if (searchRes.status === "fulfilled") {
      setSearchResult(searchRes.currentData ? searchRes.currentData : []);
    } else if (searchRes.isError) {
      toast("Failed to search...", {
        hideProgressBar: true,
        type: "error",
      });
      setSearchResult([]);
    }
  }, [searchRes]);

  function rowClickHandler(row) {
    setParams({ badge_number: row.badgeNumber });
  }

  useEffect(() => {
    if (status === "fulfilled") {
      dispatch(
        updateMDMSHierarchyProgress({
          ...data.data,
        })
      );
    } else if (isError) {
      toast("Failed to search...", {
        hideProgressBar: true,
        type: "error",
      });
    }
  }, [isError, status, data]);

  useEffect(() => {
    const main = document.querySelector("#main");
    function handlerMainClick() {
      setShowPanel(false);
    }
    main.addEventListener("click", handlerMainClick);
    return () => {
      main.removeEventListener("click", handlerMainClick);
    };
  }, []);

  return (
    <>
      {moduleName === "mdms" && (
        <form
          className="nav-item-search"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="mdms-search-container">
            <Input
              type="text"
              className="mdms-search-text-input"
              placeholder="Search by Account ID, Meter Number or Name"
              style={{ backgroundColor: "transparent" }}
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              value={searchQuery}
              onFocus={() => {
                setShowPanel(true);
              }}
              autoFocus={true}
            />
            {showPanel && (
              <div
                className="mdms-search-result-panel"
                onClick={() => {
                  setShowPanel(false);
                }}
              >
                {searchResult.length && !searchRes.isFetching ? (
                  <div className="mdms-search-result-table-container webi-scroller">
                    <table className="mdms-search-result-table">
                      <thead>
                        <tr>
                          <th>Badge Number</th>
                          <th>Account Id</th>
                          <th>Mobile Number</th>
                          <th>Meter Serial</th>
                        </tr>
                      </thead>
                      <tbody>
                        {searchResult.map((row, index) => (
                          <tr
                            key={row.badgeNumber}
                            onClick={() => {
                              rowClickHandler(row);
                            }}
                          >
                            <td>{row.badgeNumber}</td>
                            <td>{row.accountId}</td>
                            <td>{row.mobileNumber}</td>
                            <td>{row.meterSrno}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    style={{ minHeight: "22px" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {searchRes.isFetching ? (
                      <div className="dot-pulse"></div>
                    ) : (
                      <div>No results...</div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      )}
    </>
  );
};

export default NavbarSearch;
