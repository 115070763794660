import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Container,
  CardText,
  CardTitle,
  Row,
  Col,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { ChevronLeft, Eye, EyeOff } from "react-feather"; // Import Eye and EyeOff icons
import { useForceChangePasswordMutation } from "@/api/cognito-user-access-panel";
import { toast } from "react-toastify";

const ForceChangePassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
    session: localStorage.getItem("session"),
    email: localStorage.getItem("email"),
  });

  // State for toggling password visibility
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [forceChangePassword, response] = useForceChangePasswordMutation();
  const passwordRules = [
    {
      rule: /^(?=.*[a-z]).+$/, // At least one lowercase letter
      message: "Contains at least 1 lowercase letter",
    },
    {
      rule: /^(?=.*[A-Z]).+$/, // At least one uppercase letter
      message: "Contains at least 1 uppercase letter",
    },
    {
      rule: /^(?=.*\d).+$/, // At least one number
      message: "Contains at least 1 number",
    },
    {
      rule: /^(?=.*[@$!%*?&\^#]).+$/, // At least one special character
      message: "Contains at least 1 special character",
    },
    {
      rule: /^.{8,}$/, // Minimum length of 8 characters
      message: "8-character minimum length",
    },
  ];

  const [passwordValidations, setPasswordValidations] = useState(
    passwordRules.map(() => false)
  );

  const validatePassword = (password) => {
    const validations = passwordRules.map((rule) => rule.rule.test(password));
    setPasswordValidations(validations);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      toast("New password and confirm password do not match.", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }

    if (formData.newPassword === state?.data?.oldPassword) {
      toast("Your new password cannot be same as your old password.", {
        hideProgressBar: true,
        type: "error",
      });
      return;
    }

    forceChangePassword({ ...formData, oldPassword: state?.data?.oldPassword });
  };

  useEffect(() => {
    if (response.status === "fulfilled") {
      toast(
        "Password changed successfully. Please sign in with a new password",
        {
          hideProgressBar: true,
          type: "success",
        }
      );
      navigate("/");
    } else if (response.isError) {
      toast(response?.error?.data?.message || "Something went wrong...", {
        hideProgressBar: true,
        type: "error",
      });
    }
  }, [response, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "newPassword") {
      validatePassword(value);
    }
  };

  return (
    <div className="auth-wrapper auth-v2">
      <Row className="auth-inner m-0">
        <Col className="position-absolute">
          <Link
            className="brand-logo text-decoration-none l-0 d-flex align-items-center gap-2"
            to="/"
          >
            <img
              src={`${process.env.PUBLIC_URL}/polaris-logo.svg`}
              alt="Avdhaan Logo"
              style={{
                width: "30px",
                height: "30px",
              }}
            />
            <h1
              className="custom-heading"
              style={{
                fontWeight: "bold",
                color: "#0A3690",
                margin: "0px",
                fontFamily: "sans-serif",
              }}
            >
              Avdhaan
            </h1>
            <div
              style={{
                borderLeft: "2px solid #0a369054",
                height: "20px",
              }}
            ></div>
            <h2
              style={{
                marginBottom: 0,
                color: "#0a3690b0",
              }}
            >
              Sangai
            </h2>{" "}
          </Link>
        </Col>
        <Col
          className="d-none d-lg-flex align-items-center p-5"
          lg="8"
          sm="12"
        >
          <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <img
              className="img-fluid"
              src={"forgot-password-v2.svg"}
              alt="Forgot password"
            />
          </div>
        </Col>
        <Col
          className="d-flex align-items-center auth-bg px-2 p-lg-5"
          lg="4"
          sm="12"
        >
          <Col
            className="px-xl-2 mx-auto"
            sm="8"
            md="6"
            lg="12"
          >
            <CardTitle
              tag="h2"
              className="font-weight-bold mb-1"
            >
              Password Change Required
            </CardTitle>
            <CardText className="mb-2">
              Enter your new password, and confirm the new password.
            </CardText>
            <Container>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="newPassword">New Password</Label>
                  <div className="position-relative">
                    <InputGroup>
                      <Input
                        type={isNewPasswordVisible ? "text" : "password"}
                        name="newPassword"
                        id="newPassword"
                        placeholder="Enter your new password"
                        value={formData.newPassword}
                        onChange={handleChange}
                      />
                      <InputGroupText
                        className="cursor-pointer"
                        onClick={() =>
                          setIsNewPasswordVisible(!isNewPasswordVisible)
                        }
                      >
                        {isNewPasswordVisible ? (
                          <EyeOff size={16} />
                        ) : (
                          <Eye size={16} />
                        )}
                      </InputGroupText>
                    </InputGroup>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="confirmPassword">Confirm New Password</Label>
                  <div className="position-relative">
                    <InputGroup>
                      <Input
                        type={isConfirmPasswordVisible ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm your new password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                      <InputGroupText
                        className="cursor-pointer"
                        onClick={() =>
                          setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                        }
                      >
                        {isConfirmPasswordVisible ? (
                          <EyeOff size={16} />
                        ) : (
                          <Eye size={16} />
                        )}
                      </InputGroupText>
                    </InputGroup>
                  </div>
                  <div className="mt-4">
                    <Label>Password Policy:</Label>
                    <ul>
                      {passwordRules.map((rule, index) => (
                        <li
                          key={index}
                          style={{ listStyleType: "none" }}
                        >
                          {passwordValidations[index] ? (
                            <span
                              style={{
                                color: "green",
                                marginRight: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              ✓
                            </span>
                          ) : (
                            <span style={{ color: "red", marginRight: "10px" }}>
                              ✗
                            </span>
                          )}
                          <small>{rule.message}</small>
                        </li>
                      ))}
                    </ul>
                  </div>
                </FormGroup>
                <Button
                  color="primary"
                  type="submit"
                  disabled={response.isLoading}
                >
                  {response.isLoading ? "Changing..." : "Change Password"}
                </Button>
              </Form>
            </Container>
            <p className="text-center mt-2">
              <Link
                className="text-decoration-none"
                to="/"
              >
                <ChevronLeft
                  className="mr-25"
                  size={14}
                />
                <span className="align-middle text-decoration-none">
                  Back to login
                </span>
              </Link>
            </p>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default ForceChangePassword;
