import moment from "moment";
import { jwtDecode } from "jwt-decode";

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed", // for input hover border-color
  },
});

export const caseInsensitiveSort = (rowA, rowB, column) => {
  // console.log("rowA", rowA, "rowB", rowB, "column", column)

  // console.log(rowA[column], rowB[column])
  // console.log(typeof rowA[column], typeof rowB[column])

  let a = "--";
  if (
    rowA[column] === null ||
    rowA[column] === "" ||
    rowA[column] === undefined
  ) {
    a = "--";
  } else {
    if (typeof rowA[column] === "number") {
      a = rowA[column];
    } else {
      a = rowA[column]
        .toString()
        .toLowerCase()
        .replace(/^\s+|\s+$/gm, "");
    }
  }

  let b = "--";
  if (
    rowB[column] === null ||
    rowB[column] === "" ||
    rowB[column] === undefined
  ) {
    b = "--";
  } else {
    if (typeof rowB[column] === "number") {
      b = rowB[column];
    } else {
      b = rowB[column]
        .toString()
        .toLowerCase()
        .replace(/^\s+|\s+$/gm, "");
    }
  }

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export function formatDateTime(dateTimeStr, type) {
  const dateObj = new Date(dateTimeStr);
  if (type === "DATE") {
    return moment(dateObj).format("YYYY-MM-DD");
  } else if (type === "TIME") {
    return moment(dateObj).format("hh:mm:ss A");
  }
  return moment(dateObj).format("DD-MM-YYYY hh:mm:ss A");
}

export function csvTxtToJSON(txt) {
  txt = txt.trim();
  const raw = txt.split("\n");
  const keys = raw
    .shift()
    .split(",")
    .map((i) => i.trim());

  const rows = raw.map((rawRow) => {
    const rowValues = rawRow.split(",");
    const row = {};
    keys.map((key, index) => {
      return (row[key] = rowValues[index].trim());
    });
    return row;
  });
  return rows;
}

const eventCode = {
  2551: "CB ON",
  2552: "CB OFF",
  2553: "Earth Fault Relay ON",
  2554: "Earth Fault Relay OFF",
  2555: "Overload Relay ON",
  2556: "Overload Relay OFF",
  2557: "DI-4 ON",
  2558: "DI-4 OFF",
  51: "Occurrence:Current Reverse",
  67: "Occurrence:Over Current",
  69: "Occurrence:Earth loading",
  101: "Occurrence:Power Fail",
  151: "Occurrence:RTC Configure",
  152: "Occurrence:Demand Integration Period",
  153: "Occurrence:Profile Capture Period",
  154: "Occurrence:Single Action Schedule for Billing Date",
  155: "Occurrence:Activity Calendar for Time Zones",
  157: "Occurrence:New Firmware Activated",
  158: "Occurrence:Load Limit (kW) Set",
  159: "Occurrence:Enable-Load Limit Function",
  160: "Occurrence:Disable - Load Limit Function",
  161: "Occurrence:LLS Secrel (MR) Change",
  162: "Occurrence:HLS Key (US) Change",
  163: "Occurrence:HLS Key (FW) Change",
  164: "Occurrence:Global Key Change (encryption and authentication)",
  165: "Occurrence:ESWF Change",
  166: "Occurrence:MD Reset",
  201: "Occurrence:Abnormal external magnetic influence",
  203: "Occurrence:Neutral Disturbance",
  207: "Occurrence:Single Wire Operation",
  209: "Occurrence:Plug in Communication module removal",
  211: "Occurrence:Configuration change to post-paid mode",
  212: "Occurrence:Configuration change to pre-paid mode",
  213: "Occurrence:Configuration change to 'Forward only' mode",
  214: "Occurrence:Configuration change to 'Imp. and (Exp.)' mode",
  215: "Occurrence:Overload",
  251: "Occurrence:Meter Cover Open",
  301: "Occurrence:Load switch status-Disconnected",
  302: "Occurrence:Load switch status-Connected",
  1: "R Phase - Voltage missing for 3 phase meter",
  2: "R Phase - Voltage missing for 3 phase meter",
  3: "Y Phase - Voltage missing Common to 3 Phase and single phase meter",
  4: "Y Phase - Voltage missing Common to 3 Phase and single-phase meter",
  5: "B Phase - Voltage missing",
  6: "B Phase - Voltage missing",
  7: "Occurrence: Over Voltage",
  8: "Restoration: Over Voltage",
  9: "Occurrence: Low Voltage",
  10: "Restoration: Low Voltage",
  11: "Voltage unbalance",
  12: "Voltage unbalance",
  53: "Y Phase current reverse (Import type only)",
  55: "B Phase current reverse (Import type only)",
  63: "Current unbalance",
  65: "Current bypass/short",
  205: "Occurrence: Low PF",
  85: "Occurrence: Last Gasp",
  86: "Restoration: First Breath",
  87: "Increment in billing counter (Manual/MRI reset)",
  102: "Restoration: Power Fail",
  52: "Restoration: Current Reverse",
  54: "Y Phase current reverse (Import type only)",
  56: "B Phase current reverse (Import type only)",
  64: "Current unbalance",
  66: "Current bypass/short",
  68: "Restoration: Over Current",
  206: "Restoration: Low PF",
  208: "Restoration:Single Wire Operation",
  210: "Restoration:Plug in Communication module removal",
  216: "Restoration:Overload",
  70: "Restoration: Earth loading",
  202: "Restoration: Abnormal external magnetic influence",
  204: "Restoration: Neutral Disturbance",
  252: "Occurrence:Switch Weld ",
  190: "Push Destination IP/Port configuration",
  57: "Occurance R Phase CT open",
  58: "Restoration R Phase CT open",
  59: "Occurance Y Phase CT open",
  60: "Restoration Y Phase CT open",
  61: "Occurance B Phase CT open",
  62: "Restoration B Phase CT open",
};

// console.log(eventCode)

const getUpdatedData = (
  command_sequence,
  data,
  key_sequence,
  keysToConvertWh,
  keysToConvertVAh
) => {
  const updated_data = [];

  for (let i = 0; i < data.length; i++) {
    const temp = {};

    const eventCodeKey = data[i]["event_code"];
    if (eventCode.hasOwnProperty(eventCodeKey)) {
      temp[command_sequence["event"]] = eventCode[eventCodeKey];
    }

    for (const prop in data[i]) {
      if (prop in command_sequence) {
        if (
          keysToConvertWh &&
          keysToConvertWh.includes(command_sequence[prop])
        ) {
          if (data[i][prop] !== 0 && data[i][prop] !== "0") {
            temp[command_sequence[prop]] = data[i][prop] / 1000; // Convert from Wh to kWh

            temp[command_sequence[prop]] =
              temp[command_sequence[prop]]?.toFixed(4);
          } else {
            temp[command_sequence[prop]] = data[i][prop];
          }
        } else if (
          keysToConvertVAh &&
          keysToConvertVAh.includes(command_sequence[prop])
        ) {
          if (data[i][prop] !== 0 && data[i][prop] !== "0") {
            temp[command_sequence[prop]] = data[i][prop] / 1000; // Convert from Wh to kWh

            temp[command_sequence[prop]] =
              temp[command_sequence[prop]]?.toFixed(4);
          } else {
            temp[command_sequence[prop]] = data[i][prop];
          }
        } else {
          temp[command_sequence[prop]] = data[i][prop];
        }
      }
    }

    updated_data.push(temp);
    if (updated_data.filter((e) => Object.keys(e).length).length === 0) {
      return data;
    }
  }

  const ordered_updated_data = [];
  for (let i = 0; i < updated_data.length; i++) {
    const temp = {};
    for (let j = 0; j < key_sequence.length; j++) {
      temp[key_sequence[j]] = updated_data[i][key_sequence[j]];
    }
    ordered_updated_data.push(temp);
  }

  return ordered_updated_data.filter((e) => Object.keys(e).length);
};

const getBlockLoadCommmandSequence = (data) => {
  const command_sequence = {
    blockload_datetime: "Real Time Clock - Date and Time",
    avg_voltage: "Average voltage",
    import_Wh: "Block Energy, kWh – import",
    import_VAh: "Block Energy, kVAh – import",
    export_Wh: "Block Energy, kWh – export",
    export_VAh: "Block Energy, kVAh – export",
  };

  const key_sequence = Object.values(command_sequence);
  const keysToConvertWh = [
    "Block Energy, kWh – import",
    "Block Energy, kVAh – import",
    "Block Energy, kWh – export",
    "Block Energy, kVAh – export",
  ]; // Specify the keys to convert from Wh to kWh dynamically
  const keysToConvertVAh = [
    "Block_Imp._Apparent_Energy_(kVAh)",
    "Block_Exp._Apparent_Energy_(kVAh)",
  ]; // Specify the keys to convert from VAh to kVAh dynamically

  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertWh,
    keysToConvertVAh
  );
};

const getBlockLoadCommmandSequenceLTCT = (data) => {
  const command_sequence = {
    blockload_datetime: "Real time clock, date and time",
    Rphase_BLS_current: "Current, IR",
    Yphase_BLS_current: "Current, IY",
    Bphase_BLS_current: "Current, IB",
    RN_BLS_voltage: "Voltage, VRN/VRY",
    YN_BLS_voltage: "Voltage, VYN",
    BN_BLS_voltage: "Voltage, VBN/VBY",
    import_Wh: "Block energy, kWh (Import)",
    export_Wh: "Block energy, kWh (Export)",
    reactive_energy_Q1: "Block energy, kvarh-Q1",
    reactive_energy_Q2: "Block energy, kvarh-Q2",
    reactive_energy_Q3: "Block energy, kvarh-Q3",
    reactive_energy_Q4: "Block energy, kvarh-Q4",
    reactive_energy_export: "Block energy, kVAh (Import)",
    reactive_energy_import: "Block energy, kVAh (Export)",
  };
  const key_sequence = Object.values(command_sequence);
  const keysToConvertWh = [
    "Block energy, kWh (Import)",
    "Block energy, kWh (Export)",
    "Block energy, kvarh-Q1",

    "Block energy, kvarh-Q2",

    "Block energy, kvarh-Q3",

    "Block energy, kvarh-Q4",

    "Block energy, kVAh (Import)",
    "Block energy, kVAh (Export)",
  ]; // Specify the keys to convert from Wh to kWh dynamically
  const keysToConvertVAh = [
    "Block_Imp._Apparent_Energy_(kVAh)",
    "Block_Exp._Apparent_Energy_(kVAh)",
  ]; // Specify the keys to convert from VAh to kVAh dynamically

  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertWh,
    keysToConvertVAh
  );
};

const getDailyLoadCommandSequence = (data) => {
  const command_sequence = {
    dailyload_datetime: "Real Time Clock, Date and Time",
    export_Wh: "Cumulative energy, kWh – export",
    export_VAh: "Cumulative energy, kVAh – export",
    import_Wh: "Cumulative energy, kWh – import",
    import_VAh: "Cumulative energy, kVAh – import",
  };
  const key_sequence = Object.values(command_sequence);
  const keysToConvertWh = [
    "Cumulative energy, kWh – export",
    "Cumulative energy, kVAh – export",
    "Cumulative energy, kWh – import",
    "Cumulative energy, kVAh – import",
  ];
  const keysToConvertVAh = [
    "Cum._Apparent_Exp._Energy_(kVAh)",
    "Cum._Apparent_Imp._Energy_(kVAh)",
  ];
  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertWh,
    keysToConvertVAh
  );
};

const getDailyLoadCommandSequenceLTCT = (data) => {
  const command_sequence = {
    dailyload_datetime: "Real Time Clock, Date and Time",
    import_Wh: "Cumulative energy kWh (Import)",
    export_Wh: "Cumulative energy kWh (Export)",
    import_VAh: "Cumulative energy kVAh (Import)",
    export_VAh: "Cumulative energy kVAh (Export)",
  };
  const key_sequence = Object.values(command_sequence);
  const keysToConvertWh = [
    "Cumulative energy kWh (Import)",
    "Cumulative energy kWh (Export)",
    "Cumulative energy kVAh (Import)",
    "Cumulative energy kVAh (Export)",
  ];
  const keysToConvertVAh = [
    "Cum._Apparent_Exp._Energy_(kVAh)",
    "Cum._Apparent_Imp._Energy_(kVAh)",
  ];
  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertWh,
    keysToConvertVAh
  );
};

const getNamePlateCommandSequence = (data) => {
  const command_sequence = {
    "Meter Serial Number": "Meter serial number",
    "Device ID": "Device ID",
    "Manufacturer name": "Manufacturer name",
    "Firmware version for meter": "Firmware version for meter",
    Current_Rating: "Current rating",
    "Manufacturing Year": "Meter year of manufacture",
  };
  const key_sequence = Object.values(command_sequence);
  return getUpdatedData(command_sequence, data, key_sequence);
};

const getProfileInstantCommandSequenceLTCT = (data) => {
  const command_sequence = {
    meter_current_datetime: "Real time clock, date and time",
    Rphase_current: "Current, IR",
    Yphase_current: "Current, IY",
    Bphase_current: "Current, IB",
    RN_voltage: "Voltage, VRN/VRY",
    BN_voltage: "Voltage, VYN",
    YN_voltage: "Voltage, VBN/VBY",
    Bphase_signed_PF: "Signed power factor, R-phase",
    Rphase_signed_PF: "Signed power factor, Y-phase",
    Yphase_signed_PF: "Signed power factor, B-phase",
    PF: "Three phase power factor, PF",
    frequency: "Frequency, Hz",
    apparent_power_VA: "Apparent power, kVA",
    active_power_W: "Signed active power, kW (+ Forward; – Reverse)",
    signed_active_power_KVAR: "Signed reactive power, kvar (+ Lag; – Lead)",
    num_power_fail: "Number of power — failures",
    num_power_fail_dur: "Cumulative power — OFF duration in min",
    cumm_tamper_count: "Cumulative tamper count",
    cumm_billing_count: "Cumulative billing count",
    cumm_programming_count: "Cumulative programming count",
    import_kWh: "Cumulative energy, kWh (Import)",
    export_kWh: "Cumulative energy, kWh (Export)",
    cumm_energy_VArh_Q1: "Cumulative energy kvarh-Q1",
    cumm_energy_VArh_Q2: "Cumulative energy kvarh-Q2",
    cumm_energy_VArh_Q3: "Cumulative energy kvarh-Q3",
    cumm_energy_VArh_Q4: "Cumulative energy kvarh-Q4",
    import_kVAh: "Cumulative energy, kVAh (Import)",
    export_kVAh: "Cumulative energy, kVAh (Export)",
    MD_kW: "Maximum demand, kW",
    MD_kVA: "Maximum demand, kVA",
  };
  const key_sequence = Object.values(command_sequence);

  const keysToConvertWh = [
    "Load limit value, in kW",
    "Cumulative energy, kWh –Export",
    "Cumulative Energy, kWh (import)",
    "Cumulative Energy, kVAh (import)",
    "apparent_power_kVA",
    "load_limit_value (kW)",
    "Cumulative energy kvarh-Q1",
    "Cumulative energy kvarh-Q2",
    "Cumulative energy kvarh-Q3",
    "Cumulative energy kvarh-Q4",
    "export_kWh",
    "import_kWh",
    "active_power_kW",
    "Maximum Demand, kW",
    "Maximum Demand , kVA",
    "Apparent Power, kVA",
    "Active Power, kW",
  ];
  const keysToConvertVAh = [
    "MD_kVA",
    "Cumulative energy, kVAh –Export",
    "export_kVAh",
    "import_kVAh",
    "apparent_power_kVA",
  ];
  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertWh,
    keysToConvertVAh
  );
};

const getProfileInstantCommandSequence = (data) => {
  const command_sequence = {
    meter_current_datetime: "Real time clock, date and time",
    voltage: "Voltage",
    phase_current: "Phase Current (Ip)",
    neutral_current: "Neutral Current (In)",
    PF: "Signed power factor",
    frequency: "Frequency, Hz",
    apparent_power_VA: "Apparent Power, kVA",
    active_power_W: "Active Power, kW",
    import_kWh: "Cumulative Energy, kWh (import)",
    import_kVAh: "Cumulative Energy, kVAh (import)",
    MD_kW: "Maximum Demand, kW",
    MD_kVA: "Maximum Demand , kVA",
    cumm_power_on_dur_minute: "Cumulative power ON duration in min",
    cumm_tamper_count: "Cumulative tamper count",
    cumm_billing_count: "Cumulative billing count",
    cumm_programming_count: "Cumulative programming count",
    export_kWh: "Cumulative energy, kWh –Export",
    export_kVAh: "Cumulative energy, kVAh –Export",
    load_limit_func_status: "Load limit function status",
    load_limit_value: "Load limit value, in kW",
  };
  const key_sequence = Object.values(command_sequence);

  const keysToConvertWh = [
    "Load limit value, in kW",
    "Cumulative energy, kWh –Export",
    "Cumulative Energy, kWh (import)",
    "Cumulative Energy, kVAh (import)",
    "apparent_power_kVA",
    "load_limit_value (kW)",
    "cumm_energy_kVArh_Q1",
    "cumm_energy_kVArh_Q2",
    "cumm_energy_kVArh_Q3",
    "cumm_energy_kVArh_Q4",
    "export_kWh",
    "import_kWh",
    "active_power_kW",
    "Maximum Demand, kW",
    "Maximum Demand , kVA",
    "Apparent Power, kVA",
    "Active Power, kW",
  ];
  const keysToConvertVAh = [
    "MD_kVA",
    "Cumulative energy, kVAh –Export",
    "export_kVAh",
    "import_kVAh",
    "apparent_power_kVA",
  ];
  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertWh,
    keysToConvertVAh
  );
};

const getBillingCommandSequence = (data) => {
  const command_sequence = {
    billing_datetime: "Billing date import mode",
    avg_PF: "Average power factor for billing period",
    cum_import_Wh: "Cumulative energy, kWh - import",
    import_Wh_TOD_1: "Cumulative energy, kWh for TZ1",
    import_Wh_TOD_2: "Cumulative energy, kWh for TZ2",
    import_Wh_TOD_3: "Cumulative energy, kWh for TZ3",
    import_Wh_TOD_4: "Cumulative energy, kWh for TZ4",
    cum_import_Vah: "Cumulative energy, kVAh import",
    import_VAh_TOD_1: "Cumulative energy, kVAh for TZ1",
    import_VAh_TOD_2: "Cumulative energy, kVAh for TZ2",
    import_VAh_TOD_3: "Cumulative energy, kVAh for TZ3",
    import_VAh_TOD_4: "Cumulative energy, kVAh for TZ4",
    MD_W: "MD, kW",
    MD_VA: "MD, kVA",
    total_poweron_duraion_min:
      "Billing power ON duration in minutes (During billing period)",
    export_Wh: "Cumulative energy, kWh - Export",
    export_VAh: "Cumulative energy, kVAh - Export",
  };
  const key_sequence = Object.values(command_sequence);
  const keysToConvertkWh = [
    "Cumulative energy, kWh - import",
    "Cumulative energy, kWh for TZ1",
    "Cumulative energy, kWh for TZ2",
    "Cumulative energy, kWh for TZ3",
    "Cumulative energy, kWh for TZ4",
    "Cumulative energy, kVAh import",
    "Cumulative energy, kVAh for TZ1",
    "Cumulative energy, kVAh for TZ2",
    "Cumulative energy, kVAh for TZ3",
    "Cumulative energy, kVAh for TZ4",
    "MD, kW",
    "MD, kVA",
    "Cumulative energy, kWh - Export",
    "Cumulative energy, kVAh - Export                                                                           ",
  ];
  const keysToConvertVAh = [];
  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertkWh,
    keysToConvertVAh
  );
};

const getBillingCommandSequenceLTCT = (data) => {
  const command_sequence = {
    cum_import_Wh: "Cumulative energy, kWh",
    Wh_export_TZ1: "Cumulative energy, kWh for TZ1",
    Wh_export_TZ2: "Cumulative energy, kWh for TZ2",
    Wh_export_TZ3: "Cumulative energy, kWh for TZ3",
    Wh_export_TZ4: "Cumulative energy, kWh for TZ4",
    Wh_export_TZ5: "Cumulative energy, kWh for TZ5",
    Wh_export_TZ6: "Cumulative energy, kWh for TZ6",
    Wh_export_TZ7: "Cumulative energy, kWh for TZ7",
    Wh_export_TZ8: "Cumulative energy, kWh for TZ8",
    cum_import_Vah: "Cumulative energy, kVAh",
    VAh_export_TZ1: "Cumulative energy, kVAh for TZ1",
    VAh_export_TZ2: "Cumulative energy, kVAh for TZ2",
    VAh_export_TZ3: "Cumulative energy, kVAh for TZ3",
    VAh_export_TZ4: "Cumulative energy, kVAh for TZ4",
    VAh_export_TZ5: "Cumulative energy, kVAh for TZ5",
    VAh_export_TZ6: "Cumulative energy, kVAh for TZ6",
    VAh_export_TZ7: "Cumulative energy, kVAh for TZ7",
    VAh_export_TZ8: "Cumulative energy, kVAh for TZ8",
    MD_kW: "MD, kW",
    MD_W_export_TZ1: "MD, kW for TZ1",
    MD_W_export_TZ2: "MD, kW for TZ2",
    MD_W_export_TZ3: "MD, kW for TZ3",
    MD_kVA: "MD, kVA",
    MD_VA_export_TZ1: "MD, kVA for TZ1",
    MD_VA_export_TZ2: "MD, kVA for TZ2",
    MD_VA_export_TZ3: "MD, kVA for TZ3",
    total_poweron_duration_min:
      "Billing power ON duration in minutes (During billing period)",
    export_Wh: "Cumulative energy, kWh (Export)",
    export_VAh: "Cumulative energy, kVAh (Export)",
    cumm_energy_VArh_Q1: "Cumulative energy, kvarh-Q1",
    cumm_energy_VArh_Q2: "Cumulative energy, kvarh-Q2",
    cumm_energy_VArh_Q3: "Cumulative energy, kvarh-Q3",
    cumm_energy_VArh_Q4: "Cumulative energy, kvarh-Q4",
  };
  const key_sequence = Object.values(command_sequence);
  const keysToConvertWh = [
    "Cumulative energy, kWh for TZ1",
    "Cumulative energy, kWh for TZ2",
    "Cumulative energy, kWh for TZ3",
    "Cumulative energy, kWh for TZ4",
    "Cumulative energy, kWh for TZ5",
    "Cumulative energy, kWh for TZ6",
    "Cumulative energy, kWh for TZ7",
    "Cumulative energy, kWh for TZ8",
    "Cumulative energy, kVAh",
    "MD, kW",
    "MD, kW for TZ1",
    "MD, kW for TZ2",
    "MD, kW for TZ3",
    "MD, kVA for TZ1",
    "MD, kVA for TZ2",
    "MD, kVA for TZ3",
    "MD, kVA",
    "Cumulative energy, kWh (Export)",

    "Cumulative energy, kVAh (Export)",
    "Cumulative energy, kVAh for TZ1",
    "Cumulative energy, kVAh for TZ2",
    "Cumulative energy, kVAh for TZ3",
    "Cumulative energy, kVAh for TZ4",
    "Cumulative energy, kVAh for TZ5",
    "Cumulative energy, kVAh for TZ6",
    "Cumulative energy, kVAh for TZ7",
    "Cumulative energy, kVAh for TZ8",
    "Cumulative energy, kWh (Export)",
    "Cumulative energy, kVAh (Export)",
    "Cumulative energy, kvarh-Q1",

    "Cumulative energy, kvarh-Q2",

    "Cumulative energy, kvarh-Q3",

    "Cumulative energy, kvarh-Q4",
  ];
  const keysToConvertVAh = [
    "MD_kVA",
    "Cum._Apparent_Imp._Energy_(kVAh)",
    "Cum._Apparent_Imp._Energy_(kVAh)_T1",
    "Cum._Apparent_Imp._Energy_(kVAh)_T2",
    "Cum._Apparent_Imp._Energy_(kVAh)_T3",
    "Cum._Apparent_Imp._Energy_(kVAh)_T4",
    "Cum._Apparent_Exp._Energy_(kVAh)",
  ];
  return getUpdatedData(
    command_sequence,
    data,
    key_sequence,
    keysToConvertWh,
    keysToConvertVAh
  );
};

const getEventCurrentCommandSequence = (data) => {
  const command_sequence = {
    "Date and Times of Event": "Date and time of event",
    "Event Code": "Event code",
    Current: "Current",
    Voltage: "Voltage",
    "Power Factor": "Power factor",
    "Cumulative Energy – Wh": "Cumulative Energy – kWh",
  };
  const key_sequence = Object.values(command_sequence);
  const keysToConvertWh = ["Cumulative Energy – kWh"];
  return getUpdatedData(command_sequence, data, key_sequence, keysToConvertWh);
};

const getEventCurrentCommandSequenceLTCT = (data) => {
  const command_sequence = {
    measured_current: "Event Snap Current",
    voltage: "Voltage",
    PF: "Signed power factor",
    cumm_energy_Wh: "Cumulative Energy – kWh Import/forwarded",
    cumm_tamper_count: "Cumulative Tamper count",
  };
  const key_sequence = Object.values(command_sequence);
  const keysToConvertWh = ["Cumulative Energy – kWh Import/forwarded"];
  return getUpdatedData(command_sequence, data, key_sequence, keysToConvertWh);
};

const getEventVoltageComammandSequence = (data) => {
  const command_sequence = {
    event_datetime: "Date_and_Time_Of_Events",
    event_code: "Event_Code",
    measured_current: "Current",
    voltage: "Voltage",
    PF: "Power_Factor",
    cumm_energy_Wh: "Cumulative_Energy_(Kwh)",
    cumm_tamper_count: "Cumulative_Tamper_count",
    exec_datetime: "Exec_datetime",
  };
  const key_sequence = [
    "Date_and_Time_Of_Events",
    "Event_Code",
    "Current",
    "Voltage",
    "Power_Factor",
    "Cumulative_Energy_(Kwh)",
    "Cumulative_Tamper_count",
    "Exec_datetime",
  ];
  const keysToConvertWh = ["Cumulative_Energy_(Kwh)"];
  return getUpdatedData(command_sequence, data, key_sequence, keysToConvertWh);
};

const getAllEventsCommandSequence = (data) => {
  const command_sequence = {
    event_datetime: "Date_and_Time_Of_Events",
    event_code: "Event_Code",
    event: "Event",
    measured_current: "Current",
    voltage: "Voltage",
    PF: "Power_Factor",
    cumm_energy_Wh: "Cumulative_Energy_(Kwh)",
    cumm_tamper_count: "Cumulative_Tamper_count",
    data_type: "data_type",
    BN_voltage: "BN_voltage",
    RN_voltage: "RN_voltage",
    YN_voltage: "YN_voltage",
    meter_number: "meter_number",
    Bphase_current: "Bphase_current",
    Rphase_current: "Rphase_current",
    Yphase_current: "Yphase_current",
    cumm_energy_VH: "Cumulative_Energy_(Kvh)",
    Bphase_signed_PF: "Bphase_signed_PF",
    Rphase_signed_PF: "Rphase_signed_PF",
    Yphase_signed_PF: "Yphase_signed_PF",
    cumm_tamper_count: "cumm_tamper_count",
    cummulative_event_count: "cummulative_event_count",
  };
  const key_sequence = [
    "Date_and_Time_Of_Events",
    "Event_Code",
    "Event",
    "Current",
    "Voltage",
    "Power_Factor",
    "Cumulative_Energy_(Kwh)",
    "Cumulative_Energy_(Kvh)",
    "Cumulative_Tamper_count",
    "data_type",
    "BN_voltage",
    "RN_voltage",
    "YN_voltage",
    "meter_number",
    "Bphase_current",
    "Rphase_current",
    "Yphase_current",
    "event_datetime",
    "Bphase_signed_PF",
    "Rphase_signed_PF",
    "Yphase_signed_PF",
    "cumm_tamper_count",
    "cummulative_event_count",
  ];
  const keysToConvertWh = [
    "Cumulative_Energy_(Kwh)",
    "Cumulative_Energy_(Kvh)",
  ];
  return getUpdatedData(command_sequence, data, key_sequence, keysToConvertWh);
};

const getEventPowerCommandSequence = (data) => {
  const command_sequence = {
    event_datetime: "Date_and_Time_Of_Events",
    event_code: "Event_Code",
    exec_datetime: "Exec_datetime",
  };
  const key_sequence = [
    "Date_and_Time_Of_Events",
    "Event_Code",
    "Exec_datetime",
  ];
  return getUpdatedData(command_sequence, data, key_sequence);
};

const getEventNonRollOverCommandSequence = (data) => {
  const command_sequence = {
    event_datetime: "Date_and_Time_Of_Events",
    event_code: "Event_Code",
    exec_datetime: "Exec_datetime",
  };
  const key_sequence = [
    "Date_and_Time_Of_Events",
    "Event_Code",
    "Exec_datetime",
  ];
  return getUpdatedData(command_sequence, data, key_sequence);
};

const getOtherEventCommandSequence = (data) => {
  const command_sequence = {
    event_datetime: "Date_and_Time_Of_Events",
    event_code: "Event_Code",
    measured_current: "Current",
    voltage: "Voltage",
    PF: "Power_Factor",
    cumm_energy_Wh: "Cumulative_Energy_(Kwh)",
    cumm_tamper_count: "Cumulative_Tamper_count",
    exec_datetime: "Exec_datetime",
  };
  const key_sequence = [
    "Date_and_Time_Of_Events",
    "Event_Code",
    "Current",
    "Voltage",
    "Power_Factor",
    "Cumulative_Energy_(Kwh)",
    "Cumulative_Tamper_count",
    "Exec_datetime",
  ];
  const keysToConvertWh = ["Cumulative_Energy_(Kwh)"];
  return getUpdatedData(command_sequence, data, key_sequence, keysToConvertWh);
};
export const DLMSCommandMapping = (commandName, DataForTable) => {
  if (commandName === "BLOCK_LOAD") {
    const keysToCheck = [
      "Rphase_BLS_current",
      "Yphase_BLS_current",
      "Bphase_BLS_current",
    ];
    // Flag to check if all keys are present
    let allKeysExist = true;

    for (const item of DataForTable) {
      for (const key of keysToCheck) {
        if (!(key in item)) {
          allKeysExist = false;
          break; // Exit the inner loop if any key is missing
        }
      }
    }
    if (allKeysExist) {
      return getBlockLoadCommmandSequenceLTCT(DataForTable);
    } else {
      return getBlockLoadCommmandSequence(DataForTable);
    }
  }

  if (commandName === "DAILY_LOAD") {
    const keysToCheck = [
      "cumm_energy_VArh_Q2",
      "cumm_energy_VArh_Q3",
      "cumm_energy_VArh_Q4",
    ];
    // Flag to check if all keys are present
    let allKeysExist = true;

    for (const item of DataForTable) {
      for (const key of keysToCheck) {
        if (!(key in item)) {
          allKeysExist = false;
          break; // Exit the inner loop if any key is missing
        }
      }
    }
    if (allKeysExist) {
      return getDailyLoadCommandSequenceLTCT(DataForTable);
    } else return getDailyLoadCommandSequence(DataForTable);
  }
  if (commandName === "NAME_PLATE_DETAIL") {
    return getNamePlateCommandSequence(DataForTable);
  }
  if (commandName === "PROFILE_INSTANT") {
    const keysToCheck = ["BN_voltage", "RN_voltage", "YN_voltage"];
    let allKeysExist = true;

    for (const item of DataForTable) {
      for (const key of keysToCheck) {
        if (!(key in item)) {
          allKeysExist = false;
          break; // Exit the inner loop if any key is missing
        }
      }
    }
    if (allKeysExist) {
      return getProfileInstantCommandSequenceLTCT(DataForTable);
    } else return getProfileInstantCommandSequence(DataForTable);
  }
  if (commandName === "BILLING") {
    const keysToCheck = [
      "cumm_energy_VArh_Q1",
      "cumm_energy_VArh_Q2",
      "cumm_energy_VArh_Q3",
      "cumm_energy_VArh_Q4",
    ];
    let allKeysExist = true;

    for (const item of DataForTable) {
      for (const key of keysToCheck) {
        if (!(key in item)) {
          allKeysExist = false;
          break; // Exit the inner loop if any key is missing
        }
      }
    }
    if (allKeysExist) {
      return getBillingCommandSequenceLTCT(DataForTable);
    } else return getBillingCommandSequence(DataForTable);
  }
  if (commandName === "EVENT_CURRENT") {
    const keysToCheck = ["PF"];
    let allKeysExist = true;

    for (const item of DataForTable) {
      for (const key of keysToCheck) {
        if (!(key in item)) {
          allKeysExist = false;
          break; // Exit the inner loop if any key is missing
        }
      }
    }
    if (allKeysExist) {
      return getEventCurrentCommandSequenceLTCT(DataForTable);
    } else return getEventCurrentCommandSequence(DataForTable);
  }

  if (commandName === "EVENT_VOLTAGE") {
    return getEventVoltageComammandSequence(DataForTable);
  }

  if (commandName === "EVENTS" || commandName === "EVENT_DI") {
    return getAllEventsCommandSequence(DataForTable);
  }

  if (commandName === "EVENT_POWER") {
    return getEventPowerCommandSequence(DataForTable);
  }

  if (commandName === "EVENT_OTHER") {
    return getOtherEventCommandSequence(DataForTable);
  }

  if (commandName === "EVENT_NON_ROLLOVER") {
    return getEventNonRollOverCommandSequence(DataForTable);
  }
  return DataForTable;
};

export const getDefaultDateTimeRange = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0".concat(dd);
  if (mm < 10) mm = "0".concat(mm);

  let start_date = "";
  let end_date = "";

  start_date = start_date.concat(yyyy, "-", mm, "-", "01", " 00:00:00");
  end_date = end_date.concat(yyyy, "-", mm, "-", dd, " 23:59:59");

  return { startDateTime: start_date, endDateTime: end_date };
};

export const iconsStore = {
  colors: ["info", "danger", "success", "warning", "primary"],
  icons: [
    "TrendingUp",
    "Cpu",
    "Download",
    "ZapOff",
    "Calendar",
    "Check",
    "ChevronRight",
    "ChevronLeft",
    "Clock",
    "Bell",
    "User",
    "Grid",
    "Activity",
    "RefreshCw",
    "MapPin",
    "Sliders",
    "Settings",
    "Unlock",
    "Circle",
    "Award",
  ],
};

export const getNavBar = (accessArray) => {
  if (accessArray.length > 0) {
    const newArray = accessArray
      .map((e) => {
        if (e.id === "Utility") {
          return e.children
            .filter((j) => j.title === "MSPDCL")
            .map((j) => j.children)
            .flat();
        }
      })
      .filter((e) => e !== undefined)
      .flat();

    return newArray;
  }
  return [];
};

export const formattedDate = (date) => {
  const d = new Date(date);
  let month = d.getMonth() + 1;
  let day = d.getDate();
  const year = d.getFullYear();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return [year, month, day].join("-");
};

export const formattedDateTime = (date) => {
  const d = new Date(date);
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const day = String(d.getDate()).padStart(2, "0");
  const year = d.getFullYear().toString().substr(-2);

  // console.log(`${year}${month}${day}`)

  let hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
  hours = hours < 10 ? "0".concat(hours) : hours;
  const am_pm = d.getHours() >= 12 ? "PM" : "AM";

  const minutes =
    d.getMinutes() < 10 ? "0".concat(d.getMinutes()) : d.getMinutes();
  const seconds =
    d.getSeconds() < 10 ? "0".concat(d.getSeconds()) : d.getSeconds();

  const time = `${hours}:${minutes}:${seconds} ${am_pm}`;

  return [day, month, year].join("-").concat(" ", time);
};

export const keyMappings = {
  cumm_import_VAh: "cumm_import_kVah",
  cumm_import_Wh: "cumm_import_kWh",
  MD_W: "MD_kW",
  MD_VA: "MD_kVA",
  cumm_energy_VArh_Q2: "cumm_energy_kVArh_Q2",
  cumm_VARH_lag: "cumm_energy_kVArh_Q1",
  cumm_energy_VArh_Q3: "cumm_energy_kVArh_Q3",
  cumm_energy_VArh_Q4: "cumm_energy_kVArh_Q4",
  import_Wh_TOD_1: "import_kWh_TOD_1",
  import_Wh_TOD_2: "import_kWh_TOD_2",
  import_Wh_TOD_3: "import_kWh_TOD_3",
  import_Wh_TOD_4: "import_kWh_TOD_4",
  import_Wh_TOD_5: "import_kWh_TOD_5",
  import_Wh_TOD_6: "import_kWh_TOD_6",
  import_Wh_TOD_7: "import_kWh_TOD_7",
  import_Wh_TOD_8: "import_kWh_TOD_8",
  import_VAh_TOD_1: "import_kVAh_TOD_1",
  import_VAh_TOD_2: "import_kVAh_TOD_2",
  import_VAh_TOD_3: "import_kVAh_TOD_3",
  import_VAh_TOD_4: "import_kVAh_TOD_4",
  import_VAh_TOD_5: "import_kVAh_TOD_5",
  import_VAh_TOD_6: "import_kVAh_TOD_6",
  import_VAh_TOD_7: "import_kVAh_TOD_7",
  import_VAh_TOD_8: "import_kVAh_TOD_8",
  MD_W_TOD_5: "MD_kW_TOD_5",
  MD_W_TOD_4: "MD_kW_TOD_4",
  MD_W_TOD_3: "MD_kW_TOD_3",
  MD_W_TOD_2: "MD_kW_TOD_2",
  MD_W_TOD_1: "MD_kW_TOD_1",
  MD_W_TOD_6: "MD_kW_TOD_6",
  MD_W_TOD_7: "MD_kW_TOD_7",
  MD_W_TOD_8: "MD_kW_TOD_8",
  MD_VA_TOD_5: "MD_kVA_TOD_5",
  MD_VA_TOD_4: "MD_kVA_TOD_4",
  MD_VA_TOD_3: "MD_kVA_TOD_3",
  MD_VA_TOD_2: "MD_kVA_TOD_2",
  MD_VA_TOD_1: "MD_kVA_TOD_1",
  MD_VA_TOD_6: "MD_kVA_TOD_6",
  MD_VA_TOD_7: "MD_kVA_TOD_7",
  MD_VA_TOD_8: "MD_kVA_TOD_8",
  active_power_W: "active_power_kW",
  Wh_export_TZ1: "kWh_export_TZ1",
  Wh_export_TZ2: "kWh_export_TZ2",
  Wh_export_TZ3: "kWh_export_TZ3",
  Wh_export_TZ4: "kWh_export_TZ4",
  Wh_export_TZ5: "kWh_export_TZ5",
  Wh_export_TZ6: "kWh_export_TZ6",
  Wh_export_TZ7: "kWh_export_TZ7",
  Wh_export_TZ8: "kWh_export_TZ8",
  VAh_export_TZ1: "kVAh_export_TZ1",
  VAh_export_TZ2: "kVAh_export_TZ2",
  VAh_export_TZ3: "kVAh_export_TZ3",
  VAh_export_TZ4: "kVAh_export_TZ4",
  VAh_export_TZ5: "kVAh_export_TZ5",
  VAh_export_TZ6: "kVAh_export_TZ6",
  VAh_export_TZ7: "kVAh_export_TZ7",
  VAh_export_TZ8: "kVAh_export_TZ8",
};

export function capitalize(str) {
  if (!str) return ""; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export const getAllowedRoutes = (array) => {
  if (!array || array.length < 0) {
    return;
  }
  const map = new Map();

  array.forEach((e, i) => {
    const key = `${e.module}-${e.route}`;
    if (!map.has(key)) {
      map.set(key, { title: e.module, navLink: e.route, id: i, key: e.module });
    }
  });

  return Array.from(map.values());
};

export const sortResponseByKeySequence = (response, keySequence) => {
  return response.map((item) => {
    const sortedItem = {};
    keySequence.forEach((key) => {
      sortedItem[key] = item[key] === 0 ? "0" : item[key];
    });
    return sortedItem;
  });
};
