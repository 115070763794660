import React, { lazy, Suspense } from 'react';
import { HashRouter, Routes, Route, Outlet } from 'react-router-dom';
import LayoutWrapper from '../../components/layout/LayoutWrapper';
import Loader from '../../components/loader/fallbackLoader';
import Error from '../Error';
import { defaultRoutes, authRoutes } from './Routes';
import PrivateRoute from './ProtectedRoute'; // Import the PrivateRoute component
import { jwtDecode } from 'jwt-decode';
const LazyLogin = lazy(() => import('../login'));
const LazyForgotPassword = lazy(() => import('../forgotPassword'));
const AccessControlPanel = lazy(() => import('@/pages/access-control-panel'));
import useGetRoutes from '@/hooks/useGetRoutes';

const Router = () => {
  const { cleanRoutes } = useGetRoutes();
  const idToken = localStorage.getItem('idToken');
  const userDetails =
    (idToken && typeof idToken === 'string' && jwtDecode(idToken)) || '';
  return (
    <HashRouter>
      <Suspense
        fallback={<Loader height={'800px'} imgSrc={'polaris-logo.svg'} />}
      >
        <Routes>
          <Route path="/" element={<LazyLogin />} />
          <Route element={<PrivateRoute />}>
            {defaultRoutes.map((route) => {
              return (
                cleanRoutes?.includes(route.navLink) && (
                  <Route
                    key={route.key}
                    path={route.navLink}
                    element={
                      <LayoutWrapper>
                        {React.createElement(route.component)}
                      </LayoutWrapper>
                    }
                  />
                )
              );
            })}
          </Route>

          {authRoutes.map((e) => (
            <Route
              key={e.key}
              path={e.navLink}
              element={React.createElement(e.component)}
            />
          ))}

          <Route path="forgot-password" element={<LazyForgotPassword />} />

          {userDetails['cognito:groups']?.[0] === 'admin' && (
            <Route
              path="access-control-panel"
              element={
                <LayoutWrapper>
                  <AccessControlPanel />
                </LayoutWrapper>
              }
            />
          )}
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default Router;
