import { lazy } from "react";
import HesIcon from "@/assets/images/svg/hes.svg";
import MdmsIcon from "@/assets/images/svg/mdms.svg";
import PrePaidConfigIcon from "@/assets/images/svg/report.svg";
import FacotryFileIcon from "@/assets/images/svg/scheduling.svg";
import PrepaidDashIcon from "@/assets/images/svg/energy-audit.svg";
import ForceChangePassword from "../forceChangePassword";
const LazySlaReports = lazy(() => import("@/pages/utility/module/sla-Reports"));
const LazyPrepaidConfig = lazy(() =>
  import("@/pages/utility/module/prepaid-config")
);
const LazyFactoryPush = lazy(() =>
  import("@/pages/utility/module/factory-push")
);
const LazyPrepaidDashboard = lazy(() =>
  import("@/pages/utility/module/prepaid-dashboard")
);

const LazyDTFeederMonitoring = lazy(() =>
  import("@/pages/utility/module/dtr-feeder-monitoring")
);

const LazyDashboard = lazy(() =>
  import("@/pages/utility/module/reporting-superset")
);

const LazyBillingDashboard = lazy(() =>
  import("@/pages/utility/module/billing-dashboard")
);

const MfaVerify = lazy(() => import("@/pages/verifyMfa"));

const SetupMfa = lazy(() => import("@/pages/setupMfa"));

const LazyMdmsUtility = lazy(() => import("../utility/module/mdms"));
const LazyHes = lazy(() => import("@/pages/utility/module/hesv1"));
const Nfms = lazy(() => import("@/pages/utility/module/nfms"));

const authRoutes = [
  {
    id: 1,
    navLink: "verify-mfa",
    key: "verify-mfa",
    component: MfaVerify,
  },
  {
    id: 2,
    navLink: "mfa-setup",
    key: "setup-mfa",
    component: SetupMfa,
  },
  {
    id: 3,
    navLink: "force-change-password",
    key: "force-change-password",
    component: ForceChangePassword,
  },
];

const defaultRoutes = [
  {
    id: 1,
    title: "PREPAID CONFIGURATION",
    navLink: "/utility/mspdcl/prepaid-configuration",
    icon: PrePaidConfigIcon,
    key: "mspdcl-preconfig",
    component: LazyPrepaidConfig, // Assign lazy-loaded component
  },
  {
    id: 2,
    title: "PREPAID DASHBOARD",
    navLink: "/utility/mspdcl/prepaid-dashboard",
    icon: PrepaidDashIcon,
    key: "mspdcl-predash",
    component: LazyPrepaidDashboard, // Assign lazy-loaded component
  },
  {
    id: 3,
    title: "BILLING DASHBOARD",
    navLink: "/utility/mspdcl/billing-dashboard",
    icon: HesIcon,
    key: "mspdcl-billing-dashboard",
    component: LazyBillingDashboard, // Assign lazy-loaded component
  },

  {
    id: 4,
    title: "DTR & FEEDER MONITORING",
    navLink: "/utility/mspdcl/dt-feeder-monitoring",
    icon: HesIcon,
    key: "mspdcl-dt-feeder-monitor",
    component: LazyDTFeederMonitoring, // Assign lazy-loaded component
  },
  {
    id: 5,
    title: "DASHBOARD",
    navLink: "/utility/mspdcl/dashboard",
    icon: HesIcon,
    key: "mspdcl-dashboard",
    component: LazyDashboard, // Assign lazy-loaded component
  },
  {
    id: 6,
    title: "FACTORY PUSH",
    navLink: "/utility/mspdcl/factory-push",
    icon: FacotryFileIcon,
    key: "mspdcl-factory-push",
    component: LazyFactoryPush, // Assign lazy-loaded component
  },
  {
    id: 8,
    title: "SLA-Reports",
    navLink: "/utility/mspdcl/sla-reports",
    icon: FacotryFileIcon,
    key: "mspdcl-sla-reports",
    component: LazySlaReports, // Assign lazy-loaded component
  },
  {
    id: 9,
    title: "HES",
    navLink: "/utility/mspdcl/hes",
    icon: HesIcon,
    key: "mspdcl-hes",
    component: LazyHes, // Assign lazy-loaded component
  },
  {
    id: 10,
    title: "MDMS",
    navLink: "/utility/mspdcl/mdms",
    icon: MdmsIcon,
    key: "mspdcl-mdms",
    component: LazyMdmsUtility, // Assign lazy-loaded component
  },
  {
    id: 12,
    title: "REPORTS",
    navLink: "/utility/mspdcl/reports",
    icon: HesIcon,
    key: "report",
  },
  {
    id: 13,
    title: "NFMS",
    navLink: "/utility/mspdcl/nfms",
    icon: HesIcon,
    key: "nfms",
    component: Nfms,
  },
];

export { defaultRoutes, authRoutes };
