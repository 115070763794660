import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useGetRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const allowedRoutes = useSelector((state) => state.routes.routes);

  useEffect(() => {
    if (allowedRoutes.length > 0) {
      setRoutes(allowedRoutes);
      localStorage.setItem('routes', JSON.stringify(allowedRoutes));
    } else {
      const localStorageRoutes = localStorage.getItem('routes');
      const parsedRoutes = localStorageRoutes
        ? JSON.parse(localStorageRoutes)
        : [];
      setRoutes(parsedRoutes);
    }
  }, [allowedRoutes]);

  const cleanRoutes = routes.map((e) => e.navLink) || [];
  return {
    cleanRoutes
  };
};

export default useGetRoutes;
